import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Card} from "primereact/card";
import {Button} from "primereact/button";
import {Tag} from "primereact/tag";
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {InputSwitch} from "primereact/inputswitch";
import {classNames} from "primereact/utils";
import {Dropdown} from "primereact/dropdown";
import {Form, Field} from 'react-final-form';
import {InputText} from "primereact/inputtext";
import {Message} from "primereact/message";
import moment from "moment";
import {Badge} from "primereact/badge";

import SubscriptionModulesService from "../services/subscription/SubscriptionModulesService";
import SubscriptionAddService from "../services/subscription/SubscriptionAddService";
import SubscriptionPreparePayService from "../services/subscription/SubscriptionPreparePayService";
import SubscriptionStartPayService from "../services/subscription/SubscriptionStartPayService";
import SubscriptionListService from "../services/subscription/SubscriptionListService";
import SubscriptionCancelService from "../services/subscription/SubscriptionCancelService";

export const SubscriptionPage = (props) => {

  const [view, setView] = useState('subscription');
  const [yearlyPrices, setYearlyPrices] = useState(false);
  const [prices, setPrices] = useState([])
  const [tot, setTot] = useState({
    totYearly: 0,
    totMonthly: 0,
  });
  const [modules, setModules] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedModules, setSelectedModules] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState({});
  const [paymentPrepare, setPaymentPrepare] = useState({
    invoiceCompanyAddressCountryCode: "HU"
  });
  const paymentPrepareValidate = (data) => {
    let errors = {};
    if (!data.paymentMethod) {
      errors.paymentMethod = 'Fizetési mód választása kötelező';
    }
    if (!data.invoiceCompany) {
      errors.invoiceCompany = 'Kötelező mező';
    }
    if (!data.invoiceCompanyVatNumber) {
      errors.invoiceCompanyVatNumber = 'Kötelező mező';
    }
    if (!data.invoiceCompanyAddressCity) {
      errors.invoiceCompanyAddressCity = 'Kötelező mező';
    }
    if (!data.invoiceCompanyAddressZip) {
      errors.invoiceCompanyAddressZip = 'Kötelező mező';
    }
    if (!data.invoiceCompanyAddressDetail) {
      errors.invoiceCompanyAddressDetail = 'Kötelező mező';
    }
    return errors;
  };
  const paymentPrepareOnSubmit = (data, form) => {
    setLoading(true)
    setPaymentPrepare({
      ...paymentPrepare,
      data
    });
    if (data.paymentMethod === "simplepay") {
      SubscriptionStartPayService(data)
        .then(response => {
          window.location.replace(response.data.url);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    } else {
      setLoading(false)
      SubscriptionStartPayService(data)
        .then(response => {
          setView('payment_transfer')
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  useEffect(() => {
    let totYearly = 0
    let totMonthly = 0
    Object.entries(selectedModules).forEach((key) => {
      let price = prices.filter(item => item.feature === key[1].code)
      if (price[0]) {
        totYearly = totYearly + price[0].yearlyFee
        totMonthly = totMonthly + price[0].monthlyFee
      }
    })
    setTot({
      totYearly: totYearly,
      totMonthly: totMonthly
    })
  }, [selectedModules]);

  useEffect(() => {
    document.title = 'Előfizetési csomagok' + ' - ' + process.env.REACT_APP_TITLE;
    if (document.getElementsByClassName('activeMenu')[0]) {
      document.getElementsByClassName('activeMenu')[0].classList.remove("activeMenu")
    }

    if (window.App.urlVars().r) {
      setView('payment_return')
      let statusBase64 = window.App.urlVars().r
      let base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
      if (base64regex.test(statusBase64)) {
        let status = atob(statusBase64)
        let statusJson = JSON.parse(status)
        setPaymentStatus(statusJson)
      } else {
        setPaymentStatus({
          e: 'CANCEL'
        })
      }
    }

    SubscriptionModulesService()
      .then(response => {
        SubscriptionListService()
          .then(response => {
            setSubscriptions(response.data)
            let _selectedModules = []
            response.data.map((item, index) => {
              _selectedModules.push({
                code: item.code,
              })
            })
            setSelectedModules(_selectedModules)
          })
          .catch(error => {
            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          })
        let prices = []
        let modules = []
        response.data.modules.map(function (module, index) {
          console.log(module)
            modules.push({
              code: module.code,
              module: module.name,
              description: module.description,
              currency: "HUF"
            })
        })
        setPrices(prices)
        setModules(modules)
        setLoading(false)
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        setLoading(false)
      })
  }, []);

  return (
    <div className="DashboardPage SubscriptionPage p-component p-p-3">
      <div className={"p-grid p-d-flex p-flex-wrap"}>
        <div className={"p-col-12 p-p-5 left"}>
          <div className={"p-p-5"} style={{maxWidth: '1100px', marginLeft: 'auto', marginRight: 'auto'}}>
            <h2 className={"p-p-0 p-mt-0 p-mb-0 text-primary p-text-center"}>
              Előfizetés és licenszek
            </h2>
            <p className={"p-p-0 p-m-0 p-text-center"}>Válaszd ki a számodra legjobb ajánlatunkat</p>
            <h3 className={"p-text-center w-75 p-mb-5"}>
              Számlázás és bizonylatolás, Pénzügy, CRM, Készletezés és beszerzés, Gyártás és termelés,
              Projekt kezelés, HR, Munkavédelem, Döntéstámogatás és üzleti intelligencia
            </h3>
            <Card className={"p-p-3"}>
              <div className={"p-grid"}>
                <div className={"p-col p-col-align-center"}>
                  <h3 className={"p-p-0 p-m-0"}>
                    <i className={"pi pi-cloud"} style={{fontSize: '1.2rem'}}></i> Felhő alapú előfizetés
                  </h3>
                </div>
                <div className={"p-col-12 p-lg-5 p-col-align-center p-text-right"}>
                  <a className={"p-text-secondary"} onClick={() => {
                    if (window.confirm('A lemondás megerősítésére van szükség! Biztos lemondod a szolgáltatást?')) {
                      SubscriptionCancelService()
                        .then(response => {
                          window.App.toastShow('success', 'Sikeres lemondás!', 'Az előfizetés lemondása sikeresen megtörtént.', '');
                          window.location.replace('/#/');
                          window.App.forceUpdate()
                        })
                        .catch(error => {
                          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                        })
                    }
                  }}>Előfizetés lemondása</a>
                </div>
              </div>
              {view === 'subscription' &&
              <>
                <DataTable className={"p-mt-3"}
                           emptyMessage={"Nincs találat."}
                           value={modules}
                           selectionMode="checkbox"
                           selection={selectedModules}
                           dataKey="code"
                           onSelectionChange={(e) => {
                             setSelectedModules(e.value)
                           }}
                >
                  <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
                  <Column header="Modul" field="module"></Column>
                  <Column header="Funkció"
                          body={(rowData) => {
                            return (
                              <>
                                {rowData.feature}
                                <small className={"p-d-block p-text-secondary"}>{rowData.description}</small>
                              </>
                            )
                          }}
                  ></Column>
                  <Column header="Ár" className={"p-text-right p-text-nowrap"}
                          body={(rowData) => {
                            return (
                              <>
                                {yearlyPrices === false && rowData.monthlyFee &&
                                <span className={"p-d-block"}>
                                          {rowData.monthlyFee} {rowData.currency} / hó
                                    </span>
                                }
                                {yearlyPrices === true && rowData.yearlyFee &&
                                <span className={"p-d-block"}>
                                          {rowData.yearlyFee} {rowData.currency} / év
                                    </span>
                                }
                              </>
                            )
                          }}
                          style={{width: '150px'}}
                  ></Column>
                  <Column style={{width: '120px'}}
                          header="Érvényes"
                          body={(rowData) => {
                            let feature = subscriptions.filter(item => item.code === rowData.code)
                            if (feature && feature[0]) {
                              if (feature[0].expiresAt !== null) {
                                if (moment(feature[0].expiresAt).isAfter(new Date()) === true) {
                                  return (
                                    <Badge value={moment.utc(feature[0].expiresAt).local().format('YYYY-MM-DD')}
                                           severity="success"
                                           className={"w-100"}></Badge>
                                  )
                                } else {
                                  return (
                                    <Badge value={moment.utc(feature[0].expiresAt).local().format('YYYY-MM-DD')}
                                           severity="danger"
                                           className={"w-100"}></Badge>
                                  )
                                }
                              } else {
                                return (
                                  <><Badge value="Próbaidőszak" className={"w-100"}></Badge></>
                                )
                              }
                            }
                          }}
                  ></Column>
                </DataTable>
                <div className={"p-grid p-mt-3"}>
                  <div className={"p-col-2 p-col-align-center p-text-right"}>
                    <span>Éves számlázás:</span>
                  </div>
                  <div className={"p-col p-col-align-center"}>
                    <InputSwitch
                      checked={yearlyPrices}
                      value={yearlyPrices}
                      onChange={e => setYearlyPrices(e.target.value)}/>
                  </div>
                  <div className={"p-col-4 p-text-center p-col-align-center"}>
                    Fizetendő: <strong>{yearlyPrices === false ? (<>{tot.totMonthly}</>) : (<>{tot.totYearly}</>)} HUF
                    + ÁFA</strong> {yearlyPrices === false ? (<>/ hó</>) : (<>/ év</>)}
                  </div>
                  <div className={"p-col-4 p-text-right"}>
                    <Button label="Fizetési adatok" className={"p-button-success p-button-lg"}
                            loading={loading}
                            icon={"pi pi-arrow-right"}
                            onClick={() => {
                              setLoading(true)
                              let paymentFrequency = 'monthly'
                              if (yearlyPrices === true) {
                                paymentFrequency = 'yearly'
                              }
                              let modules = []
                              Object.entries(selectedModules).forEach((entry) => {
                                modules.push({
                                  code: entry[1].code,
                                })
                              })
                              let subscriptionData = {
                                paymentFrequency: paymentFrequency,
                                features: modules
                              }
                              if (modules && modules[0]) {
                                SubscriptionAddService(subscriptionData)
                                  .then(response => {
                                  })
                                  .catch(error => {
                                  })
                                SubscriptionPreparePayService()
                                  .then(response => {
                                    setView('payment_prepare')
                                    setLoading(false)
                                    setPaymentPrepare(response.data)
                                  })
                                  .catch(error => {
                                    window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                    setLoading(false)
                                  })
                              } else {
                                setLoading(false)
                                window.App.toastShow('warn', 'Legalább egy előfizetési csomag szükséges!', 'Kérlek legalább egy csomagot jelölj meg az előfizetéshez');
                              }

                            }}
                    />
                  </div>
                </div>
                <div className={"p-grid p-mt-2"}>
                  <div className={"p-col"}>
                    <Message className={"w-100"}
                             severity="info"
                             text="
                  Előfizetési csomag módosításakor az érvényes funkciók lejárati idejét a kiválasztott időszakkal meghosszabítjuk.
                              "></Message>
                  </div>
                </div>
              </>
              }
              {view === 'payment_prepare' &&
              <>
                <Form onSubmit={paymentPrepareOnSubmit} initialValues={paymentPrepare}
                      validate={paymentPrepareValidate} render={({handleSubmit}) => (
                  <form onSubmit={handleSubmit} className="">
                    <div className={"p-grid p-mt-3"}>
                      <div className={"p-col-12 p-lg-4"}>
                        <Field name="paymentMethod" render={({input, meta}) => (
                          <div className="p-field p-fluid">
                            <label htmlFor="paymentMethod"
                                   className={classNames({'p-error': isFormFieldValid(meta)})}>Fizetési mód</label>
                            <span className="p-input-icon-right">
                                  {paymentPrepare && paymentPrepare.paymentMethod ? (
                                    <Dropdown
                                      options={[
                                        {name: "SimplePay bankkártyás fizetés", value: "simplepay"},
                                        {name: "Átutalás", value: "transfer"},
                                      ]}
                                      optionLabel="name"
                                      optionValue="value"
                                      id={"paymentMethod"}
                                      emptyMessage={"Még nincs fizetési mód..."}
                                      onChange={(e) => {
                                        setPaymentPrepare({
                                          ...paymentPrepare,
                                          paymentMethod: e.value
                                        })
                                      }}
                                      value={paymentPrepare.paymentMethod}
                                    />
                                  ) : (
                                    <Dropdown
                                      options={[
                                        {name: "SimplePay bankkártyás fizetés", value: "simplepay"},
                                        {name: "Átutalás", value: "transfer"},
                                      ]}
                                      optionLabel="name"
                                      optionValue="value"
                                      id={"paymentMethod"}
                                      emptyMessage={"Még nincs fizetési mód..."}
                                      onChange={(e) => {
                                        setPaymentPrepare({
                                          ...paymentPrepare,
                                          paymentMethod: e.value
                                        })
                                      }}
                                    />
                                  )}
                              </span>
                            {getFormErrorMessage(meta)}
                          </div>
                        )}/>
                      </div>
                      <div className={"p-col-12 p-lg-4"}>
                        <Field name="invoiceCompany" render={({input, meta}) => (
                          <div className="p-field p-fluid">
                            <label htmlFor="invoiceCompany"
                                   className={classNames({'p-error': isFormFieldValid(meta)})}>Megrendelő
                              cégnév</label>
                            <span className="p-input-icon-right">
                                <InputText id="invoiceCompany" {...input}
                                           onChange={(e) => {
                                             setPaymentPrepare({
                                               ...paymentPrepare,
                                               invoiceCompany: e.target.value
                                             })
                                           }}
                                           value={paymentPrepare.invoiceCompany}
                                           className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                              </span>
                            {getFormErrorMessage(meta)}
                          </div>
                        )}/>
                      </div>
                      <div className={"p-col-12 p-lg-4"}>
                        <Field name="invoiceCompanyVatNumber" render={({input, meta}) => (
                          <div className="p-field p-fluid">
                            <label htmlFor="invoiceCompanyVatNumber"
                                   className={classNames({'p-error': isFormFieldValid(meta)})}>Adószám</label>
                            <span className="p-input-icon-right">
                                  <InputText id="invoiceCompanyVatNumber" {...input}
                                             onChange={(e) => {
                                               setPaymentPrepare({
                                                 ...paymentPrepare,
                                                 invoiceCompanyVatNumber: e.target.value
                                               })
                                             }}
                                             value={paymentPrepare.invoiceCompanyVatNumber}
                                             className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                                </span>
                            {getFormErrorMessage(meta)}
                          </div>
                        )}/>
                      </div>
                      <div className={"p-col-12 p-lg-2"}>
                        <Field name="invoiceCompanyAddressZip" render={({input, meta}) => (
                          <div className="p-field p-fluid">
                            <label htmlFor="invoiceCompanyAddressZip"
                                   className={classNames({'p-error': isFormFieldValid(meta)})}>IRSZ</label>
                            <span className="p-input-icon-right">
                                  <InputText id="invoiceCompanyAddressZip" {...input}
                                             onChange={(e) => {
                                               setPaymentPrepare({
                                                 ...paymentPrepare,
                                                 invoiceCompanyAddressZip: e.target.value
                                               })
                                             }}
                                             value={paymentPrepare.invoiceCompanyAddressZip}
                                             className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                                </span>
                            {getFormErrorMessage(meta)}
                          </div>
                        )}/>
                      </div>
                      <div className={"p-col-12 p-lg-3"}>
                        <Field name="invoiceCompanyAddressCity" render={({input, meta}) => (
                          <div className="p-field p-fluid">
                            <label htmlFor="invoiceCompanyAddressCity"
                                   className={classNames({'p-error': isFormFieldValid(meta)})}>Város</label>
                            <span className="p-input-icon-right">
                                  <InputText id="invoiceCompanyAddressCity" {...input}
                                             onChange={(e) => {
                                               setPaymentPrepare({
                                                 ...paymentPrepare,
                                                 invoiceCompanyAddressCity: e.target.value
                                               })
                                             }}
                                             value={paymentPrepare.invoiceCompanyAddressCity}
                                             className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                                </span>
                            {getFormErrorMessage(meta)}
                          </div>
                        )}/>
                      </div>
                      <div className={"p-col-12 p-lg-7"}>
                        <Field name="invoiceCompanyAddressDetail" render={({input, meta}) => (
                          <div className="p-field p-fluid">
                            <label htmlFor="invoiceCompanyAddressDetail"
                                   className={classNames({'p-error': isFormFieldValid(meta)})}>Cím</label>
                            <span className="p-input-icon-right">
                                  <InputText id="invoiceCompanyAddressDetail" {...input}
                                             onChange={(e) => {
                                               setPaymentPrepare({
                                                 ...paymentPrepare,
                                                 invoiceCompanyAddressDetail: e.target.value
                                               })
                                             }}
                                             value={paymentPrepare.invoiceCompanyAddressDetail}
                                             className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                                </span>
                            {getFormErrorMessage(meta)}
                          </div>
                        )}/>
                      </div>
                    </div>
                    <div className={"p-grid p-mt-4"}>
                      <div className={"p-col-2 p-col-align-center p-text-right"}>
                        <span>Éves számlázás:</span>
                      </div>
                      <div className={"p-col p-col-align-center"}>
                        <InputSwitch
                          checked={yearlyPrices}
                          value={yearlyPrices}
                          onChange={e => setYearlyPrices(e.target.value)}/>
                      </div>
                      <div className={"p-col-4 p-text-center p-col-align-center"}>
                        Fizetendő: <strong>{yearlyPrices === false ? (<>{tot.totMonthly}</>) : (<>{tot.totYearly}</>)} HUF
                        + ÁFA</strong> {yearlyPrices === false ? (<>/ hó</>) : (<>/ év</>)}
                      </div>
                      <div className={"p-col-4 p-text-right"}>
                        <Button label="Fizetés" className={"p-button-success p-button-lg"}
                                loading={loading}
                                icon={"pi pi-arrow-right"}
                                type="submit"
                        />
                      </div>
                    </div>
                  </form>
                )}/>
              </>
              }
              {view === 'payment_return' &&
              <div className={"p-mt-5"}>
                {paymentStatus && paymentStatus.e &&
                <>
                  {paymentStatus.e === 'SUCCESS' &&
                  <>
                    <Message severity="success" className={"p-d-block p-text-center"}
                             text="Sikeres fizetés!" style={{fontSize: '1.4em'}}></Message>
                    <h4>Köszönjük előfizetésed!</h4>
                    <p>
                      Sikeresen előfizettél a kiválasztott modulokra, melyeket előfizetői rendszerünk azonnal
                      aktivál.
                      Az előfizetésedről számlát küldtünk emailben. A fizetéshez tartozó tranzakció
                      azonosító: <strong>{paymentStatus.t}</strong>
                    </p>
                  </>
                  }
                  {paymentStatus.e === 'FAILED' &&
                  <>
                    <Message severity="danger" className={"p-d-block p-text-center"}
                             text="Sikertelen fizetés!" style={{fontSize: '1.4em'}}></Message>
                    <h4>Hiba történt a fizetés során!</h4>
                    <p>
                      Vélhetően nincs fedezet a kártyán. A tranzakció
                      azonosítója: <strong>{paymentStatus.t}</strong>
                    </p>
                  </>
                  }
                  {paymentStatus.e === 'TIMEOUT' &&
                  <>
                    <Message severity="warn" className={"p-d-block p-text-center"}
                             text="Sikertelen fizetés!" style={{fontSize: '1.4em'}}></Message>
                    <h4>A tranzakció időtúllépés miatt megszakadt!</h4>
                    <p>
                      Az előfizetési folyamat újra megkezdhető.
                    </p>
                  </>
                  }
                  {paymentStatus.e === 'CANCEL' &&
                  <>
                    <Message severity="warn" className={"p-d-block p-text-center"}
                             text="Sikertelen fizetés!" style={{fontSize: '1.4em'}}></Message>
                    <h4>A fizetési kísérletet megszakítottad!</h4>
                    <p>
                      Az előfizetési folyamat újra megkezdhető.
                    </p>
                  </>
                  }
                </>
                }
              </div>
              }
              {view === 'payment_transfer' &&
              <div className={"p-mt-5"}>
                <h4>Köszönjük előfizetésed!</h4>
                <Message severity="warn" className={"p-d-block p-text-center p-mb-3"}
                         text="Az átutaláshoz szükséges adatok" style={{fontSize: '1.4em'}}></Message>
                Összeg: <strong>{yearlyPrices === false ? (<>{tot.totMonthly * 1.27}</>) : (<>{tot.totYearly * 1.27}</>)} HUF</strong>
                <br/>
                Közlemény: <strong>{paymentPrepare.invoiceCompanyVatNumber}</strong>
                <br/>
                Számlaszám: <strong>11711003-21474419</strong>
                <br/>
                Címzett: <strong>Trackie.io Kft.</strong>
                <p>
                  Az összeg beérkezésétől számított egy munkanapon belül aktiváljuk előfizetésed!
                </p>

              </div>
              }
            </Card>
          </div>
        </div>
      </div>
    </div>
  )
}
export default withTranslation('common')(SubscriptionPage);
