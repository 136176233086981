import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";
import {Dropdown} from "primereact/dropdown";
import {Dialog} from "primereact/dialog";

import UserFormService from '../../services/user/UserFormService'
import EmployeeListService from "../../services/employee/EmployeeListService";
import RolesListService from "../../services/roles/RolesListService";
import UserRolesService from "../../services/user/UserRolesService";
import {AutoComplete} from "primereact/autocomplete";
import RolegroupListService from "../../services/rolegroup/RolegroupListService";
import {InputSwitch} from "primereact/inputswitch";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import UserNetworkListService from "../../services/user/UserNetworkListService";
import UserNetworkDeleteService from "../../services/user/UserNetworkDeleteService";
import CompanyListService from "../../services/company/CompanyListService";
import UserCompaniesService from "../../services/user/UserComaniesService";
import UserCompanyAddService from "../../services/user/UserComanyAddService";
import UserCompanyDeleteService from "../../services/user/UserComanyDeleteService";

const UserFormComponent = (props) => {

  const [formData, setFormData] = useState({
    active: false
  });
  const [loading, setLoading] = useState(false);
  const [userRoles, setUserRoles] = useState([])
  const [userNetworks, setUserNetworks] = useState([])
  const [userCompanies, setUserCompanies] = useState([])
  const [userCompanyToAdd, setUserCompanyToAdd] = useState()
  const [companiesDialog, setCompaniesDialog] = useState(false)

  const validate = (data) => {
    let errors = {};
    // if (!data.name) {
    //   errors.name = 'Az megnevezés nem lehet üres';
    // }
    // if (!data.identifier) {
    //   errors.identifier = 'Az email nem lehet üres';
    // }
    // if (!data.roleGroups) {
    //   errors.roleGroups = 'A szerepkör meghatározása kötelező';
    // }
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData({
      ...formData,
      data
    });
    if ((props && props.initialValues) || formData.id) {
      UserFormService(data, 'put')
        .then(response => {
          window.App.toastShow('success', 'Sikeres módosítás!');
          window.App.setState({rerenderUserList: true})
          setFormData(response.data)
          if (props.closeForm === true) {
            window.App.setState({
              sidebarUserUpdate: false,
              sidebarUserCreate: false,
            })
          }
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    } else {
      UserFormService(data, 'post')
        .then(response => {
          window.App.toastShow('success', 'Sikeres mentés!');
          window.App.setState({rerenderUserList: true})
          setFormData(response.data)
          if (props.closeForm === true) {
            window.App.setState({
              sidebarUserCreate: false
            })
          }
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  // entryLocation autocomplete
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState('');

  const searchEmployee = (event) => {
    setSelectedEmployee(event.query.replace('Nincs találat', '').replace(': ', ''))
    EmployeeListService(JSON.stringify({filters: {name: {value: event.query.replace('Nincs találat', '').replace(': ', '')}}}))
      .then(response => {
        if (response.data && response.data.items && response.data.items[0]) {
          setEmployees(response.data.items)
        } else {
          let string
          if (event.query) {
            string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
          } else {
            string = 'Nincs találat'
          }
          setSelectedEmployee(string)
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  // company autocomplete
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState();

  const searchCompany = (event) => {
    setSelectedCompany(event.query.replace('Nincs találat', '').replace(': ', ''))
    CompanyListService(JSON.stringify({filters: {name: {value: event.query.replace('Nincs találat', '').replace(': ', '')}}}))
      .then(response => {
        if (response.data && response.data.items && response.data.items[0]) {
          setCompanies(response.data.items)
        } else {
          let string
          if (event.query) {
            string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
          } else {
            string = 'Nincs találat'
          }
          setSelectedCompany(string)
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  //roles
  const [roles, setRoles] = useState([]);

  //roleGroups
  const [roleGroups, setRoleGroups] = useState([]);
  const [userRoleGroups, setUserRoleGroups] = useState([]);
  const [loadingRoleGroups, setLoadingRoleGroups] = useState(false);
  const [totalRecordsRoleGroups, setTotalRecordsRoleGroups] = useState(0);
  const [rowsRoleGroups, setRowsRoleGroups] = useState(10);
  const [lazyParamsRoleGroups, setLazyParamsRoleGroups] = useState({
    first: 0,
    rows: rowsRoleGroups,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  let loadLazyTimeoutRoleGroups = null;
  const loadLazyDataRoleGroups = () => {
    setLoadingRoleGroups(true);
    if (loadLazyTimeoutRoleGroups) {
      clearTimeout(loadLazyTimeoutRoleGroups);
    }
    loadLazyTimeoutRoleGroups = setTimeout(() => {
      RolegroupListService(JSON.stringify(lazyParamsRoleGroups))
        .then(response => {
          setTotalRecordsRoleGroups(response.data.total_item_count);
          setRoleGroups(response.data.items);
          setLoadingRoleGroups(false);
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPageRoleGroups = (event) => {
    let _lazyParams = {...lazyParamsRoleGroups, ...event};
    setLazyParamsRoleGroups(_lazyParams);
  }
  const onSortRoleGroups = (event) => {
    let _lazyParams = {...lazyParamsRoleGroups, ...event};
    setLazyParamsRoleGroups(_lazyParams);
  }
  const onFilterRoleGroups = (event) => {
    let _lazyParams = {...lazyParamsRoleGroups, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParamsRoleGroups(_lazyParams);
  }
  const pagerRoleGroups = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions}
                    onChange={(e) => setRowsRoleGroups(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };

  const newtorkActionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button icon="pi pi-trash" className="p-button-sm p-button-danger"
                tooltip="Törlés"
                type="button"
                onClick={() => {
                  if (window.confirm('A törlés megerősítésére van szükség!')) {
                    UserNetworkDeleteService(formData.id, rowData.id)
                      .then(response => {
                        window.App.toastShow('success', 'Sikeres törlés!',);
                        UserNetworkListService(props.initialValues.id)
                          .then(response => {
                            setUserNetworks(response.data.items)
                          })
                          .catch(error => {
                            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                          })
                      })
                      .catch(error => {
                        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                      })

                  }
                }}
        />
      </React.Fragment>
    );
  }

  useEffect(() => {
    if (localStorage.getItem('roles').toString().includes('role_user_roles')) {
      RolesListService()
        .then(response => {
          setRoles(response.data)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
      loadLazyDataRoleGroups()
    }
    if (props && props.initialValues) {
      setFormData(props.initialValues)
      setSelectedEmployee(props.initialValues.employeeName)
      if (process.env.REACT_APP_BRAND === 'entryfish') {
        UserNetworkListService(props.initialValues.id)
          .then(response => {
            setUserNetworks(response.data.items)
          })
          .catch(error => {
            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          })
      }
      UserCompaniesService(props.initialValues.id)
        .then(response => {
          setUserCompanies(response.data.items)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })

    }
    if ((props && props.initialValues) || formData.roles) {
      let __userRoles = []
      if (props.initialValues.roles) {
        __userRoles = props.initialValues.roles
      }
      if (formData.userRoles) {
        __userRoles = formData.roles
      }
      let _userRoles = []
      Object.entries(__userRoles).map(function (role, index) {
        _userRoles.push({
          code: role[1]
        })
      })
      setUserRoles(_userRoles)
    }
  }, [lazyParamsRoleGroups])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit}) => (
      <form onSubmit={handleSubmit} className="">
        <Panel headerTemplate={
          <div className={"p-grid"}>
            <div className={"p-col-12"}>
              <h3>
                <i className={"pi pi-list"}></i> Alapadatok
              </h3>
            </div>
          </div>
        } className={""}>
          <div className={"p-grid"}>
            <div className={"p-col-12 p-md-6 p-lg-6"}>
              <Field name="name" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="name"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Név</label>
                  <span className="p-input-icon-right">
                      <InputText id="name" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     name: e.target.value
                                   })
                                 }}
                                 value={formData.name}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-md-6 p-lg-6"}>
              <Field name="identifier" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="identifier"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Azonosító (email cím)</label>
                  <span className="p-input-icon-right">
                      <InputText id="identifier" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     identifier: e.target.value
                                   })
                                 }}
                                 value={formData.identifier}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            {!props.initialValues &&
            <div className={"p-col-12 p-md-5 p-lg-5"}>
              <Field name="password" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="password"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Jelszó</label>
                  <span className="p-input-icon-right">
                      <InputText id="identifier" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     password: e.target.value
                                   })
                                 }}
                                 value={formData.password}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            }
            <div className={"p-col-12 p-md-5 p-lg-5"}>
              <Field name="employee" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="employee"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Munkavállaló</label>
                  <span className="p-input-icon-right">
                    <AutoComplete value={selectedEmployee}
                                  id={"employee"}
                                  suggestions={employees}
                      // forceSelection
                                  completeMethod={searchEmployee}
                                  field={"name"}
                                  delay="500"
                                  placeholder={"Keresés gépeléssel..."}
                                  className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                  onSelect={(e) => {
                                    setFormData({
                                      ...formData,
                                      employee: {id: e.value.id}
                                    })
                                    setSelectedEmployee(e.value.name)
                                  }}
                                  dropdown
                                  onClear={(e) => {
                                    setSelectedEmployee('')
                                  }}
                    />
                    </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
            <div className={"p-col-12 p-lg-2 p-xl-2"}>
              <Field name="active" render={({input, meta}) => (
                <div className="p-field p-fluid">
                  <label htmlFor="active"
                         className={classNames({'p-error': isFormFieldValid(meta)})}>Aktív</label>
                  <span className="p-input-icon-right">
                    <InputSwitch
                      className={"p-mt-1"}
                      checked={formData.active}
                      value={formData.active}
                      onChange={e => setFormData({...formData, active: e.target.value})}/>
                  </span>
                  {getFormErrorMessage(meta)}
                </div>
              )}/>
            </div>
          </div>
          <div className={"p-grid p-mt-3"}>
            <div className={"p-col-12 p-lg-6 p-xl-6"}>

            </div>
            <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
              <Button type="submit" label="Mentés és bezár" icon={"pi pi-check"}
                      loading={loading}
                      onClick={() => {
                        window.App.setState({useEffect: true})
                        props = {...props, closeForm: true}
                      }}
                      className="p-button-success"/>
              <Button type="submit" label="Mentés" icon={"pi pi-check"}
                      loading={loading}
                      onClick={() => {
                        props = {...props, closeForm: false}
                      }}
                      className="p-button-success p-ml-2"/>
            </div>
          </div>
        </Panel>

        {process.env.REACT_APP_USER_COMPANIES == 'true' &&
        <>
          {formData.id &&
          <Panel className={"p-mt-3"}>
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-12 p-xl-12"}>
                <div className={"p-grid"}>
                  <div className={"p-col-12"}>
                    <h3>
                      <i className={"pi pi-list"}></i> Vállalkozás hozzáférések
                    </h3>
                  </div>
                </div>
                <DataTable
                  value={userCompanies}
                  autoLayout={true}
                  filterDisplay="row"
                  responsiveLayout="scroll"
                  stripedRows
                  emptyMessage="Nincs találat."
                >
                  <Column className={""}
                          body={(rowData) => {
                            return (
                              <Button icon="pi pi-trash"
                                      loading={loading}
                                      className="p-button-sm  p-button-danger"
                                      type="button"
                                      tooltip="Törlés"
                                      onClick={() => {
                                        setLoading(true)
                                        UserCompanyDeleteService(props.initialValues.id, rowData.id)
                                          .then(response => {
                                            UserCompaniesService(props.initialValues.id)
                                              .then(response => {
                                                setUserCompanies(response.data.items)
                                                setLoading(false)
                                              })
                                              .catch(error => {
                                                setLoading(false)
                                                window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                              })
                                            window.App.toastShow('success', 'Sikeres mentés!', 'A modosítások a ' +
                                              'következő bejelentkezéskor lépnek életbe.');
                                          })
                                          .catch(error => {
                                            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                          })
                                      }}
                              />
                            )
                          }}
                  ></Column>
                  <Column field="name" header="Cég neve" sortable></Column>
                  <Column field="vatNumber" header="Adószám" sortable></Column>
                </DataTable>
                <div className={"p-grid p-mt-3"}>
                  <div className={"p-col-12 p-lg-6 p-xl-6 p-col-align-center"}>
                  </div>
                  <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                    <Button type="button" label="Új hozzáadása" icon="pi pi-plus"
                            className="p-button-success"
                            onClick={() => {
                              setCompaniesDialog(true)
                            }}
                    />
                    <Dialog visible={companiesDialog}
                            style={{width: '750px'}}
                            header="Cég hozzáadása"
                            modal
                            onHide={() => {
                              setCompaniesDialog(false)
                            }}>

                      <div className={"p-grid"}>
                        <div className={"p-col"}>
                          <div className="p-field p-fluid">
                            <label htmlFor="companies">Cégek</label>
                            <span className="p-input-icon-right">
                              {localStorage.getItem('roles').toString().includes('role_company_browse')
                              || localStorage.getItem('roles').toString().includes('role_company_read') ? (
                                <AutoComplete value={selectedCompany}
                                              id={"company"}
                                              suggestions={companies}
                                              completeMethod={searchCompany}
                                              field={"name"}
                                              delay="500"
                                              placeholder={"Keresés gépeléssel..."}
                                              onSelect={(e) => {
                                                setUserCompanyToAdd(e.value.id)
                                                setSelectedCompany(e.value.name)
                                              }}
                                              dropdown
                                              onClear={(e) => {
                                                setSelectedCompany('')
                                              }}
                                />
                              ) : (
                                <Dropdown disabled className={"disabled"} id={"company"}/>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className={"p-grid p-mt-3"}>
                        <div className={"p-col-12 p-lg-6 p-xl-6"}>
                        </div>
                        <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
                          <Button type="button" label="Mentés" icon="pi pi-check" loading={loading}
                                  onClick={() => {
                                    setLoading(true)
                                    UserCompanyAddService(props.initialValues.id, userCompanyToAdd)
                                      .then(response => {
                                        UserCompaniesService(props.initialValues.id)
                                          .then(response => {
                                            setUserCompanies(response.data.items)
                                          })
                                          .catch(error => {
                                            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                          })
                                        setLoading(false)
                                        setCompaniesDialog(false)
                                        window.App.toastShow('success', 'Sikeres mentés!');
                                      })
                                      .catch(error => {
                                        setLoading(false)
                                        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                      })
                                  }}/>
                        </div>
                      </div>
                    </Dialog>
                  </div>
                </div>
              </div>
            </div>
          </Panel>
          }
        </>
        }

        {formData.id && process.env.REACT_APP_BRAND === 'entryfish' &&
        <>
          <Panel className={"p-mt-3"}>
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-12 p-xl-12"}>
                <div className={"p-grid"}>
                  <div className={"p-col-12"}>
                    <h3>
                      <i className={"pi pi-list"}></i> Projektek
                    </h3>
                  </div>
                </div>
                <DataTable
                  value={userNetworks}
                  autoLayout={true}
                  filterDisplay="row"
                  responsiveLayout="scroll"
                  stripedRows
                  emptyMessage="Nincs találat."
                >
                  <Column body={newtorkActionBodyTemplate} className={"p-py-0 p-text-nowrap"}></Column>
                  <Column field="name" header="Projekt neve" sortable></Column>
                </DataTable>
              </div>
            </div>
          </Panel>
        </>
        }

        {localStorage.getItem('roles').toString().includes('role_user_roles') &&
        <Panel className={"p-mt-3"}>
          {formData.id ? (
            <>
              <div className={"p-grid"}>
                {localStorage.getItem('roles').toString().includes('role_role_group_read') &&
                <div className={"p-col-12 p-lg-6 p-xl-6"}>
                  <div className={"p-grid"}>
                    <div className={"p-col-12"}>
                      <h3>
                        <i className={"pi pi-list"}></i> Szerepkörök
                      </h3>
                    </div>
                  </div>
                  <DataTable
                    paginator first={lazyParamsRoleGroups.first}
                    totalRecords={totalRecordsRoleGroups} onPage={onPageRoleGroups}
                    onSort={onSortRoleGroups} sortField={lazyParamsRoleGroups.sortField}
                    sortOrder={lazyParamsRoleGroups.sortOrder}
                    onFilter={onFilterRoleGroups} filters={lazyParamsRoleGroups.filters}
                    loading={loadingRoleGroups} lazy
                    paginatorTemplate={pagerRoleGroups}
                    emptyMessage="Nincs találat."
                    value={roleGroups}
                    rows={rowsRoleGroups}
                    autoLayout={true}
                    filterDisplay="row"
                    responsiveLayout="scroll"
                    stripedRows
                  >
                    <Column field="name" header="Név" sortable filter filterPlaceholder="Név"
                            body={(rowData) => {
                              let fullGorup = false
                              let findCounter = 0
                              Object.entries(rowData.roles).map(function (role, index) {
                                let find = userRoles.filter(item => item.code === role[1])
                                if (find && find[0]) {
                                  findCounter = findCounter + 1
                                }
                                if (findCounter == rowData.roles.length) {
                                  fullGorup = true
                                }
                              })
                              if (fullGorup === true) {
                                return (
                                  <span className={"p-text-bold"}>
                                    {rowData.name}
                                  </span>
                                )
                              } else {
                                return (
                                  <span>
                                    {rowData.name}
                                  </span>
                                )
                              }
                            }}></Column>
                    <Column className={"p-text-right"}
                            body={(rowData) => {
                              let fullGorup = false
                              let findCounter = 0
                              Object.entries(rowData.roles).map(function (role, index) {
                                let find = userRoles.filter(item => item.code === role[1])
                                if (find && find[0]) {
                                  findCounter = findCounter + 1
                                }
                                if (findCounter == rowData.roles.length) {
                                  fullGorup = true
                                }
                              })
                              if (fullGorup === true) {
                                return (
                                  <Button icon="pi pi-trash" className="p-button-sm  p-button-danger"
                                          tooltip="Törlés"
                                          onClick={() => {
                                            let roles = []
                                            Object.entries(rowData.roles).map(function (role, index) {
                                              roles.push({
                                                code: role[1]
                                              })
                                            })
                                            let newRoles = userRoles
                                            Object.entries(roles).map(function (role, index) {
                                              const indexOfObject = userRoles.findIndex(object => {
                                                return object.code === role[1].code;
                                              });
                                              newRoles.splice(indexOfObject, 1)
                                            })
                                            setUserRoles(newRoles)
                                            UserRolesService(newRoles, formData.id)
                                              .then(response => {
                                                setFormData(response.data)
                                                window.App.toastShow('success', 'Sikeres mentés!', 'A modosítások a ' +
                                                  'következő bejelentkezéskor lépnek életbe.');
                                                window.App.setState({
                                                  rerenderUserList: true
                                                })
                                              })
                                              .catch(error => {
                                                window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                              })
                                          }}
                                  />
                                )
                              } else {
                                return (
                                  <Button icon="pi pi-plus" className="p-button-sm  p-button-success"
                                          tooltip="Hozzáad"
                                          onClick={() => {
                                            let newRoles = []
                                            Object.entries(rowData.roles).map(function (role, index) {
                                              newRoles.push({
                                                code: role[1]
                                              })
                                            })
                                            let _userRoles = [
                                              ...userRoles,
                                              ...newRoles
                                            ]
                                            setUserRoles(_userRoles)
                                            UserRolesService(_userRoles, formData.id)
                                              .then(response => {
                                                setFormData(response.data)
                                                window.App.toastShow('success', 'Sikeres mentés!', 'A modosítások a ' +
                                                  'következő bejelentkezéskor lépnek életbe.');
                                                window.App.setState({
                                                  rerenderUserList: true
                                                })
                                              })
                                              .catch(error => {
                                                window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                              })
                                          }}></Button>
                                )
                              }
                            }}></Column>
                  </DataTable>
                </div>
                }
                <div className={"p-col-12 p-lg-6 p-xl-6"}>
                  <div className={"p-grid"}>
                    <div className={"p-col-12"}>
                      <h3>
                        <i className={"pi pi-list"}></i> Jogosultságok
                      </h3>
                    </div>
                  </div>
                  <DataTable
                    emptyMessage="Nincs találat."
                    value={roles}
                    autoLayout={true}
                    selection={userRoles}
                    onSelectionChange={e => {
                      setUserRoles(e.value)
                    }} dataKey="code"
                    selectionMode="checkbox"
                    filterDisplay="row"
                    responsiveLayout="scroll"
                    stripedRows
                  >
                    <Column selectionMode="multiple" headerStyle={{width: '3em'}}></Column>
                    <Column field="name" header="Név" sortable filter filterPlaceholder="Név"></Column>
                  </DataTable>
                  <div className={"p-grid p-mt-3"}>
                    <div className={"p-col p-text-right"}>
                      <Button type="button" label="Jogosultságok mentése" icon="pi pi-check"
                              className="p-button-success"
                              loading={loading}
                              onClick={() => {
                                setLoading(true)
                                UserRolesService(userRoles, formData.id)
                                  .then(response => {
                                    setFormData(response.data)
                                    window.App.toastShow('success', 'Sikeres mentés!', 'A modosítások a ' +
                                      'következő bejelentkezéskor lépnek életbe.');
                                    window.App.setState({
                                      rerenderUserList: true
                                    })
                                    setLoading(false)
                                  })
                                  .catch(error => {
                                    window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                    setLoading(false)
                                  })
                              }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <><i className={"pi pi-lock"}></i> A jogosultságok beállítása csak mentés után lehetséges!</>
          )}
        </Panel>
        }
      </form>
    )}/>
  )
}
export default withTranslation('common')(UserFormComponent);
