import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";
import {Calendar} from "primereact/calendar";
import {addLocale} from 'primereact/api';
import {Dropdown} from "primereact/dropdown";
import {AutoComplete} from "primereact/autocomplete";
import moment from "moment";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {InputNumber} from "primereact/inputnumber";

import InventoryListService from "../../services/inventory/InventoryListService";
import WarehouseListService from "../../services/warehouse/WarehouseListService";
import InventorytransferFormService from "../../services/inventorytransfer/InventorytransferFormService";
import InventorytransferFinishService from "../../services/inventorytransfer/InventorytransferFinishService";

const WarehouseFromtransferFormComponent = (props) => {

  const [formData, setFormData] = useState();
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  const validate = (data) => {
    let errors = {};
    // if (!data.warehouseFrom) {
    //   errors.warehouseFrom = 'A raktár nem lehet üres';
    // }
    // if (props.initialValues.action === 'move' && !data.warehouseTo) {
    //   errors.warehouseTo = 'A raktár nem lehet üres';
    // }
    return errors;
  };

  const onSubmit = (data, form) => {
    setLoading(true)
    if (items && items[0]) {
      setFormData({
        ...formData,
        data
      });
      let _data = {
        ...data,
        products: items
      }
      InventorytransferFormService(_data, props.initialValues.action)
        .then(response => {
          window.App.toastShow('success', 'Sikeres módosítás! A véglegesítés jóváhagyást igényel.');
          // window.App.setState({
          //   sidebarInventorytransferCreate: false,
          //   sidebarInventorytransferUpdate: false,
          //   rerenderInventorytransferList: true,
          // })
          setLoading(false)
          setFormData(response.data)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
      form.restart();
    } else {
      window.App.toastShow('warn', 'Legalább egy terméket ki kell jelölni!');
      setLoading(false)
    }
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  // product list
  const [products, setProducts] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(10);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  const dt = useRef(null);

  let loadLazyTimeout = null;
  const loadLazyData = (warehouseName) => {
    setLoadingProducts(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      InventoryListService(JSON.stringify({filters: {warehouseName: {value: warehouseName.replace('Nincs találat', '').replace(': ', '')}}}))
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setProducts(response.data.items);
          setLoadingProducts(false);
        });
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };

  // warehouseFrom autocomplete
  const [warehousesFrom, setWarehousesFrom] = useState([]);
  const [selectedWarehouseFrom, setSelectedWarehouseFrom] = useState();

  const searchWarehouseFrom = (event) => {
    setSelectedWarehouseFrom(event.query.replace('Nincs találat', '').replace(': ', ''))
    WarehouseListService(JSON.stringify({filters: {name: {value: event.query.replace('Nincs találat', '').replace(': ', '')}}}))
      .then(response => {
        if (response.data && response.data.items && response.data.items[0]) {
          setWarehousesFrom(response.data.items)
        } else {
          let string
          if (event.query) {
            string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
          } else {
            string = 'Nincs találat'
          }
          setSelectedWarehouseFrom(string)
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  // warehouseTo autocomplete
  const [warehousesTo, setWarehousesTo] = useState([]);
  const [selectedWarehouseTo, setSelectedWarehouseTo] = useState();

  const searchWarehouseTo = (event) => {
    setSelectedWarehouseTo(event.query.replace('Nincs találat', '').replace(': ', ''))
    WarehouseListService(JSON.stringify({filters: {name: {value: event.query.replace('Nincs találat', '').replace(': ', '')}}}))
      .then(response => {
        if (response.data && response.data.items && response.data.items[0]) {
          setWarehousesTo(response.data.items)
        } else {
          let string
          if (event.query) {
            string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
          } else {
            string = 'Nincs találat'
          }
          setSelectedWarehouseTo(string)
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  useEffect(() => {

  }, [])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit}) => (
      <form onSubmit={handleSubmit} className="PurchaseorderrequestForm">
        <div className={"p-grid p-d-flex p-flex-wrap p-mt-3"}>
          <div className={"p-col-12 p-lg-2 p-xl-2 p-d-flex"}>
            <Panel headerTemplate={
              <div className={"p-grid"}>
                <div className={"p-col-12"}>
                  <h3>
                    <i className={"pi pi-list"}></i> Alapadatok
                  </h3>
                </div>
              </div>
            } className={"w-100"}>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-12"}>
                  <Field name="warehouseFrom" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="warehouseFrom"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Aktuális raktár</label>
                      <span className="p-input-icon-right">
                        <AutoComplete value={selectedWarehouseFrom}
                                      id={"warehouseFrom"}
                                      suggestions={warehousesFrom}
                                      // forceSelection
                                      completeMethod={searchWarehouseFrom}
                                      field={"name"}
                                      delay="500"
                                      dropdown
                                      placeholder={"Keresés gépeléssel..."}
                                      className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                      onSelect={(e) => {
                                        setFormData({
                                          ...formData,
                                          warehouseFrom: {id: e.value.id}
                                        })
                                        setSelectedWarehouseFrom(e.value.name)
                                        loadLazyData(e.value.name);
                                      }}
                                      onClear={(e) => {
                                        setSelectedWarehouseFrom('')
                                      }}
                        />
                      </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                {props.initialValues.action === 'move' &&
                <div className={"p-col-12 p-lg-12"}>
                  <Field name="warehouseTo" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="warehouseTo"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Cél raktár</label>
                      <span className="p-input-icon-right">
                        <AutoComplete value={selectedWarehouseTo}
                                      id={"warehouseTo"}
                                      suggestions={warehousesTo}
                                      // forceSelection
                                      completeMethod={searchWarehouseTo}
                                      field={"name"}
                                      delay="500"
                                      dropdown
                                      placeholder={"Keresés gépeléssel..."}
                                      className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                      onSelect={(e) => {
                                        setFormData({
                                          ...formData,
                                          warehouseTo: {id: e.value.id}
                                        })
                                        setSelectedWarehouseTo(e.value.name)
                                      }}
                                      onClear={(e) => {
                                        setSelectedWarehouseTo('')
                                      }}
                        />
                      </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                }
              </div>
            </Panel>
          </div>
          <div className={"p-col-12 p-lg-10 p-xl-10 p-d-flex"}>
            <Panel className={"w-100"}>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-6 p-xl-6"}>
                  <div className={"p-grid"}>
                    <div className={"p-col-12"}>
                      <h3>
                        {props.initialValues.action === 'move' ? (
                          <>
                            <i className={"pi pi-list"}></i> Kiválasztható termékek
                          </>
                        ) : (
                          <>
                            <i className={"pi pi-list"}></i> Termékek
                          </>
                        )}
                      </h3>
                    </div>
                  </div>
                  {products && selectedWarehouseFrom ? (
                    <DataTable
                      emptyMessage="Nincs találat."
                      value={products} paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
                      onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
                      onFilter={onFilter} filters={lazyParams.filters} loading={loadingProducts} lazy
                      paginatorTemplate={pager} rows={rows} emptyMessage="Nincs találat."
                      autoLayout={true}
                      ref={dt}
                      filterDisplay="row"
responsiveLayout="scroll"
                      stripedRows
                    >
                      <Column field="productName" header="Név" sortable filter filterPlaceholder="Név"></Column>
                      <Column field="sku" header="Termék cikkszám (SKU)" sortable filter filterPlaceholder="Termék cikkszám (SKU)"></Column>
                      <Column field="stock" header="db"></Column>
                      <Column body={(rowData) => {
                        return (
                          <>
                          {formData && !formData.id ? (
                            <>
                              <InputNumber id="amount"
                                           locale="hu-HU"
                                           minFractionDigits={0}
                                           maxFractionDigits={9}
                                           onChange={(e) => {
                                             if(e.value >= 1) {
                                               let _items = items.filter(item => item.id !== rowData.product.id)
                                               let __items = [
                                                 ..._items,
                                                 {
                                                   quantity: e.value,
                                                   id: rowData.product.id,
                                                   productName: rowData.productName
                                                 }
                                               ]
                                               setItems(__items)
                                             }
                                           }}
                              />
                            </>
                            ):(
                              <>
                                <InputNumber id="amount"
                                             locale="hu-HU"
                                             minFractionDigits={0}
                                             maxFractionDigits={9}
                                             disabled
                                />
                              </>
                            )}
                          </>
                        )
                      }}></Column>
                    </DataTable>
                  ) : (
                    <>
                      <i className={"pi pi-lock"}></i> A termékek kiválasztásához először raktárat kell választani!
                    </>
                  )}
                </div>
                <div className={"p-col-12 p-lg-6 p-xl-6"}>
                  <div className={"p-grid"}>
                    <div className={"p-col-12"}>
                      <h3>
                        {props.initialValues.action === 'move' ? (
                          <>
                            <i className={"pi pi-list"}></i> Mozgatott termékek
                          </>
                        ) : (
                          <>
                            <i className={"pi pi-list"}></i> Módosítás
                          </>
                        )}
                      </h3>
                    </div>
                  </div>
                  {products && selectedWarehouseFrom ? (
                    <DataTable
                      emptyMessage="Nincs találat."
                      value={items}
                      autoLayout={true}
                      filterDisplay="row"
responsiveLayout="scroll"
                      stripedRows
                    >
                      {formData && !formData.id &&
                      <Column body={(rowData) => {
                        return (
                          <>
                            <Button icon="pi pi-trash" className="p-button-sm  p-button-danger"
                                    tooltip="Törlés"
                                    type="button"
                                    onClick={() => {
                                      let index = items.findIndex(item => item.id === rowData.id);
                                      let _items = items
                                      _items.splice(index, 1)
                                      setTimeout(function () {
                                        setItems(_items)
                                        setFormData({
                                          ...formData,
                                          products: _items
                                        })
                                      }, 500)
                                    }}
                            />
                          </>
                        )
                      }}></Column>
                      }
                      <Column field="productName" header="Név" sortable></Column>
                      <Column field="sku" header="Termék cikkszám (SKU)" sortable></Column>
                      <Column field="quantity" header="Mennyiség"></Column>
                    </DataTable>
                  ) : (
                    <>
                      <i className={"pi pi-lock"}></i> A termékek kiválasztásához először raktárat kell választani!
                    </>
                  )}
                </div>
              </div>
            </Panel>
          </div>
        </div>
        <div className={"p-grid p-mt-2"}>
          <div className={"p-col-12 p-lg-6 p-xl-6"}>

          </div>
          <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
            {formData && !formData.id ? (
              <>
                {props.initialValues.action === 'move' ? (
                  <Button type="submit" label="Mozgatás" icon={"pi pi-check"} loading={loading}
                          className="p-button-success"/>
                ) : (
                  <Button type="submit" label="Mentés" icon={"pi pi-check"} loading={loading}
                          className="p-button-success"/>
                )}
              </>
            ):(
              <Button type="button" label="Véglegesítés" loading={loading}
                      onClick={() => {
                        InventorytransferFinishService(formData.id)
                          .then(response => {
                            window.App.toastShow('success', 'Sikeres módosítás!');
                            window.App.setState({
                              sidebarInventorytransferCreate: false,
                              sidebarInventorytransferUpdate: false,
                              rerenderInventorytransferList: true,
                            })
                            setLoading(false)
                            setFormData(response.data)
                          })
                          .catch(error => {
                            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                            setLoading(false)
                          })
                      }}
                      className="p-button-success"/>
            )}
          </div>
        </div>
      </form>
    )}/>
  )
}
export default withTranslation('common')(WarehouseFromtransferFormComponent);
