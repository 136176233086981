import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";
import {Calendar} from "primereact/calendar";
import {addLocale} from 'primereact/api';
import {Dropdown} from "primereact/dropdown";
import {AutoComplete} from "primereact/autocomplete";
import moment from "moment";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {InputNumber} from "primereact/inputnumber";

import PurchaseorderrequestFormService from '../../services/purchaseorderrequest/PurchaseorderrequestFormService'
import ProjectListService from "../../services/project/ProjectListService";
import ProductListService from "../../services/product/ProductListService";
import PurchaseorderrequestShowService from "../../services/purchaseorderrequest/PurchaseorderrequestShowService";
import PurchaseorderRequestToOrderService from "../../services/purchaseorder/PurchaseorderRequestToOrderService";
import UserListService from "../../services/user/UserListService";
import PurchaseorderrequestItemDeleteService
  from "../../services/purchaseorderrequest/PurchaseorderrequestItemDeleteService"
import InventoryListService from "../../services/inventory/InventoryListService";
import currencyFormatter from "currency-formatter";
import {InputMask} from "primereact/inputmask";

const PurchaseorderrequestFormComponent = (props) => {

  const [formData, setFormData] = useState({
    project: {id: Number(localStorage.getItem('defaultProjectId'))},
  });
  const [loading, setLoading] = useState(false);
  const [productExpandedRows, setProductExpandedRows] = useState(null);
  const [productInventory, setProductInventory] = useState([]);

  const validate = (data) => {
    let errors = {};
    // if (!data.project) {
    //   errors.project = 'A projekt nem lehet üres';
    // }
    // if (!data.deadline) {
    //   errors.deadline = 'Kötelező mező';
    // }
    // if (!data.requestedBy) {
    //   errors.requestedBy = 'Igénylőt kötelező megjelölni';
    // }
    return errors;
  };

  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData({
      ...formData,
      data
    });
    if ((props && props.initialValues) || (formData && formData.id)) {
      PurchaseorderrequestFormService(data, 'put')
        .then(response => {
          window.App.toastShow('success', 'Sikeres módosítás!');
          window.App.setState({rerenderPurchaseorderrequestList: true})
          if (props.closeForm === true) {
            window.App.setState({
              sidebarPurchaseorderrequestCreate: false,
              sidebarPurchaseorderrequestUpdate: false,
            })
          }
          setLoading(false)
          setFormData(response.data)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    } else {
      PurchaseorderrequestFormService(data, 'post')
        .then(response => {
          window.App.toastShow('success', 'Sikeres mentés!');
          window.App.setState({rerenderPurchaseorderrequestList: true})
          if (props.closeForm === true) {
            window.App.setState({
              sidebarPurchaseorderrequestCreate: false,
              sidebarPurchaseorderrequestUpdate: false,
            })
          }
          setFormData(response.data)
          setLoading(false)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
          setLoading(false)
        })
    }
    form.restart();

  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  addLocale('hu', {
    firstDayOfWeek: 1,
    dayNames: ['vasárnap', 'hétfő', 'kedd', 'szerda', 'csütörtök', 'péntek', 'szombat'],
    dayNamesShort: ['vas', 'hé', 'ke', 'sze', 'csü', 'pé', 'szo'],
    dayNamesMin: ['V', 'H', 'K', 'SZ', 'CS', 'P', 'SZ'],
    monthNames: ['január', 'február', 'március', 'április', 'május', 'június', 'július', 'augusztus', 'szeptember',
      'október', 'november', 'december'],
    monthNamesShort: ['jan', 'feb', 'mar', 'ápr', 'máj', 'jun', 'júl', 'aug', 'szep', 'okt', 'nov', 'dec'],
    today: 'Ma',
    clear: 'Töröl'
  });

  // project autocomplete
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(localStorage.getItem('defaultProjectName'));

  const searchProject = (event) => {
    setSelectedProject(event.query.replace('Nincs találat', '').replace(': ', ''))
    ProjectListService(JSON.stringify({filters: {name: {value: event.query.replace('Nincs találat', '').replace(': ', '')}}}))
      .then(response => {
        if (response.data && response.data.items && response.data.items[0]) {
          setProjects(response.data.items)
        } else {
          let string
          if (event.query) {
            string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
          } else {
            string = 'Nincs találat'
          }
          setSelectedProject(string)
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  // product list
  const [products, setProducts] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(10);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  const dt = useRef(null);

  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoadingProducts(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      //window.App.setState({rerenderGuestList: false})
      ProductListService(JSON.stringify(lazyParams))
        .then(response => {
          setTotalRecords(response.data.total_item_count);
          setProducts(response.data.items);
          setLoadingProducts(false);
          response.data.items.map(function (item, index) {
            if (document.getElementById('amount' + item.id)) {
              document.getElementById('amount' + item.id).children[0].value = ''
            }
          })
        });
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', userSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', userSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };

  // user autocomplete
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');

  const searchUsers = (event) => {
    setSelectedUser(event.query.replace('Nincs találat', '').replace(': ', ''))
    UserListService(JSON.stringify({filters: {name: {value: event.query.replace('Nincs találat', '').replace(': ', '')}}}))
      .then(response => {
        if (response.data && response.data.items && response.data.items[0]) {
          setUsers(response.data.items)
        } else {
          let string
          if (event.query) {
            string = 'Nincs találat: ' + event.query.replace('Nincs találat', '').replace(': ', '')
          } else {
            string = 'Nincs találat'
          }
          setSelectedUser(string)
        }
      })
      .catch(error => {
        window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
      })
  }

  const productRowExpansionTemplate = (data) => {
    return (
      <>
        <div className="p-ml-3">
          <DataTable value={productInventory[data.id]}
                     emptyMessage="Nincs találat."
                     autoLayout={true}
                     filterDisplay="row"
                     responsiveLayout="scroll"
                     stripedRows
          >
            <Column field="warehouseName" header="Raktár" filter sortable filterPlaceholder="Raktár"></Column>
            <Column field="stock" header="Mennyiség" filter sortable className={"p-text-right"}
                    filterPlaceholder="Mennyiség"
                    body={(rowData) => {
                      return (
                        <>{rowData.stock} {data.unitName}</>
                      )
                    }}
            ></Column>
          </DataTable>
        </div>
      </>
    );
  }

  useEffect(() => {
    if (localStorage.getItem('userId')) {
      setFormData({
        ...formData,
        requestedBy: {id: Number(localStorage.getItem('userId'))}
      })
      setSelectedUser(localStorage.getItem('userRealName'))
    }
    if (props && props.initialValues) {
      PurchaseorderrequestShowService(props.initialValues.id)
        .then(response => {
          setSelectedProject(response.data.projectName)
          setFormData(response.data)
        })
        .catch(error => {
          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
        })
    }
    loadLazyData();
  }, [lazyParams])

  return (
    <Form onSubmit={onSubmit} initialValues={formData}
          validate={validate} render={({handleSubmit}) => (
      <form onSubmit={handleSubmit} className="PurchaseorderrequestForm">
        <div className={"p-grid p-d-flex p-flex-wrap p-mt-3"}>
          <div className={"p-col-12 p-xl-12 p-d-flex"}>
            <Panel headerTemplate={
              <div className={"p-grid"}>
                <div className={"p-col-12"}>
                  <h3>
                    <i className={"pi pi-list"}></i> Alapadatok
                  </h3>
                </div>
              </div>
            } className={"w-100"}>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-6"}>
                  <Field name="project" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="project"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Projekt</label>
                      <span className="p-input-icon-right">
                    <AutoComplete value={selectedProject}
                                  id={"project"}
                                  suggestions={projects}
                      // forceSelection
                                  completeMethod={searchProject}
                                  field={"name"}
                                  delay="500"
                                  dropdown
                                  placeholder={"Keresés gépeléssel..."}
                                  className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                  onSelect={(e) => {
                                    setFormData({
                                      ...formData,
                                      project: {id: e.value.id}
                                    })
                                    setSelectedProject(e.value.name)
                                    localStorage.setItem("defaultProjectId", e.value.id)
                                    localStorage.setItem("defaultProjectName", e.value.name)
                                  }}
                                  onClear={(e) => {
                                    setSelectedProject('')
                                  }}
                    />
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-lg-1"}>
                  <Field name="deadline" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="deadline"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Határidő</label>
                      <span className="p-input-icon-right">
                        <InputMask id={"deadline"}
                                   mask={"9999-99-99"}
                                   placeholder={"ÉÉÉÉ-HH-NN"}
                                   value={formData.deadline}
                                   onChange={(e) => {
                                     if (e.value.indexOf("_") === -1) {
                                       if (e.value === '') {
                                         e.value = null
                                       }
                                       setFormData({
                                         ...formData,
                                         deadline: e.value
                                       })
                                     }
                                   }}
                        />
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col"}>
                  <Field name="requestedBy" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <label htmlFor="requestedBy"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>Igénylő</label>
                      <span className="p-input-icon-right">
                        {localStorage.getItem('roles').toString().includes('role_user_browse')
                        || localStorage.getItem('roles').toString().includes('role_user_read') ? (
                          <AutoComplete value={selectedUser}
                                        id={"requestedBy"}
                                        suggestions={users}
                            // forceSelection
                                        completeMethod={searchUsers}
                                        field={"name"}
                                        delay="500"
                                        placeholder={"Keresés gépeléssel..."}
                                        className={classNames({'p-invalid': isFormFieldValid(meta)})}
                                        onSelect={(e) => {
                                          setFormData({
                                            ...formData,
                                            requestedBy: {id: e.value.id}
                                          })
                                          setSelectedUser(e.value.name)
                                        }}
                                        dropdown
                                        onClear={(e) => {
                                          setSelectedUser('')
                                        }}
                          />
                        ) : (
                          <Dropdown disabled className={"disabled"} id={"requestedBy"}/>
                        )}
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                {/*<div className={"p-col-12 p-md-12 p-lg-12"}>*/}
                {/*  <Field name="status" render={({input, meta}) => (*/}
                {/*    <div className="p-field p-fluid">*/}
                {/*      <label htmlFor="status"*/}
                {/*             className={classNames({'p-error': isFormFieldValid(meta)})}>Státusz</label>*/}
                {/*      <span className="p-input-icon-right">*/}
                {/*            <Dropdown options={statuses}*/}
                {/*                      optionLabel="name"*/}
                {/*                      optionValue="id"*/}
                {/*                      id={"status"}*/}
                {/*                      emptyMessage={"Még nincs státusz..."}*/}
                {/*                      onChange={(e) => {*/}
                {/*                        setFormData({*/}
                {/*                          ...formData,*/}
                {/*                          status: e.value*/}
                {/*                        })*/}
                {/*                      }}*/}
                {/*                      value={formData.status}*/}
                {/*            />*/}
                {/*  </span>*/}
                {/*      {getFormErrorMessage(meta)}*/}
                {/*    </div>*/}
                {/*  )}/>*/}
                {/*</div>*/}
              </div>
            </Panel>
          </div>
          <div className={"p-col-12 p-xl-12 p-d-flex"}>
            <Panel className={"w-100"}>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-8 p-xl-8"}>
                  <div className={"p-grid"}>
                    <div className={"p-col-12"}>
                      <h3>
                        <i className={"pi pi-list"}></i> Kiválasztható termékek
                      </h3>
                    </div>
                  </div>
                  {formData && formData.id ? (
                    <DataTable
                      id={"selectable"}
                      emptyMessage="Nincs találat."
                      value={products} paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
                      onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
                      onFilter={onFilter} filters={lazyParams.filters} loading={loadingProducts} lazy
                      paginatorTemplate={pager} rows={rows} emptyMessage="Nincs találat."
                      autoLayout={true}
                      ref={dt}
                      filterDisplay="row"
                      responsiveLayout="scroll"
                      stripedRows
                      dataKey="id"
                      rowExpansionTemplate={productRowExpansionTemplate}
                      expandedRows={productExpandedRows}
                      onRowExpand={(e) => {
                        InventoryListService(JSON.stringify({
                          first: 0,
                          rows: 9999,
                          page: 0,
                          sortField: 'id',
                          sortOrder: -1,
                          filters: {productName: {value: e.data.name}}
                        }))
                          .then(response => {
                            let _inventory = productInventory
                            _inventory[e.data.id] = response.data.items
                            setProductInventory(_inventory)
                          })
                          .catch(error => {
                            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                          })
                        setTimeout(function () {
                          setProductExpandedRows({[e.data.id]: true})
                        }, 1000)

                      }}
                      // onRowToggle={(e) => {
                      //   setProductExpandedRows(e.data)
                      // }}
                    >
                      <Column field="manufacturerName" header="Gyártó" sortable filter
                              filterPlaceholder="Gyártó"></Column>
                      <Column field="name" header="Név" sortable filter filterPlaceholder="Név"></Column>
                      <Column field="sku" header="Termék cikkszám (SKU)" sortable filter
                              filterPlaceholder="Termék cikkszám (SKU)"></Column>
                      <Column body={(rowData, row) => {
                        return (
                          <div className="p-inputgroup">
                            <InputNumber
                              id={"amount" + rowData.id}
                              className={"amount"}
                              locale="hu-HU"
                              minFractionDigits={0}
                              maxFractionDigits={9}
                            />
                            <Button type="button" icon="pi pi-check" className="p-button-success" disabled={loading}
                                    onClick={(e) => {
                                      if (e.target.parentElement.parentElement.children[0].children[0].getAttribute('aria-valuenow') !== null) {
                                        setLoading(true)
                                        let items = formData.items.filter(item => item.product !== rowData.id)
                                        let _items = [
                                          ...items,
                                          {
                                            quantity: Number(e.target.parentElement.parentElement.children[0].children[0].getAttribute('aria-valuenow')),
                                            product: rowData.id,
                                            productName: rowData.name
                                          }
                                        ]
                                        let data = {
                                          ...formData,
                                          items: _items
                                        }
                                        PurchaseorderrequestFormService(data, 'put')
                                          .then(response => {
                                            setFormData(response.data)
                                            setLoading(false)
                                            document.getElementById('amount' + rowData.id).children[0].value = ''
                                          })
                                          .catch(error => {
                                            window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                            setLoading(false)
                                          })
                                      }
                                    }}/>
                          </div>
                        )
                      }}></Column>
                      <Column field="unitName" header=""></Column>
                      <Column field="averagePurchaseUnitPrice" header="Átlag nettó beszer. ért." sortable
                              className={"p-text-right p-text-nowrap"}
                              body={(rowData) => {
                                let currency
                                if (rowData.currency === 'HUF') {
                                  currency = {
                                    precision: 2,
                                    symbol: '',
                                    decimal: ',',
                                    thousand: ' ',
                                  }
                                } else {
                                  currency = {
                                    precision: 2,
                                    symbol: '',
                                    decimal: '.',
                                    thousand: ' ',
                                  }
                                }
                                return (
                                  <>
                                    {currencyFormatter.format(
                                      rowData.averagePurchaseUnitPrice, {
                                        precision: currency.precision,
                                        symbol: currency.symbol,
                                        decimal: currency.decimal,
                                        thousand: currency.thousand,
                                      }
                                    )}&nbsp;{rowData.currency}
                                  </>
                                )
                              }}></Column>
                      <Column expander style={{width: '3em'}}/>
                    </DataTable>
                  ) : (
                    <><i className={"pi pi-lock"}></i> A termékek kiválasztása csak mentés után lehetséges!</>
                  )}
                </div>
                <div className={"p-col"}>
                  <div className={"p-grid"}>
                    <div className={"p-col-12"}>
                      <h3>
                        <i className={"pi pi-list"}></i> Rendelendő termékek
                      </h3>
                    </div>
                  </div>
                  {formData && formData.id ? (
                    <DataTable
                      id={"selected"}
                      emptyMessage="Nincs találat."
                      value={formData.items}
                      autoLayout={true}
                      filterDisplay="row"
                      responsiveLayout="scroll"
                      stripedRows
                      loading={loading}
                    >
                      <Column body={(rowData) => {
                        return (
                          <>
                            <Button icon="pi pi-trash" className="p-button-sm  p-button-danger"
                                    disabled={loading}
                                    tooltip="Törlés"
                                    type="button"
                                    onClick={() => {
                                      setLoading(true)
                                      PurchaseorderrequestItemDeleteService(formData.id, rowData.id)
                                        .then(response => {
                                          if (document.getElementById('amount' + rowData.product.id)) {
                                            document.getElementById('amount' + rowData.product.id).children[0].value = ''
                                          }
                                          setLoading(false)
                                          setFormData(response.data)
                                        })
                                        .catch(error => {
                                          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                                        })
                                    }}
                            />
                          </>
                        )
                      }}></Column>
                      <Column field="productName" header="Név" sortable></Column>
                      {/*<Column field="sku" header="Termék cikkszám (SKU)" sortable></Column>*/}
                      <Column field="quantity" header="Mennyiség" className={"p-text-right"} body={(rowData) => {
                        return (
                          <>
                            {currencyFormatter.format(
                              rowData.quantity, {
                                precision: 0,
                                symbol: '',
                                decimal: '.',
                                thousand: ' ',
                              }
                            )
                            }
                          </>
                        )
                      }}></Column>
                    </DataTable>
                  ) : (
                    <><i className={"pi pi-lock"}></i> A termékek megtekintése csak mentés után lehetséges!</>
                  )}
                </div>
              </div>
            </Panel>
          </div>
        </div>
        <div className={"p-grid p-mt-3"}>
          <div className={"p-col-12 p-lg-6 p-xl-6"}>
            {formData.id &&
            <Button type="button" label="Megtekintés" icon={"pi pi-eye"}
                    onClick={() => {
                      window.App.setState({
                        props: formData,
                        sidebarPurchaseorderrequestUpdate: false,
                        sidebarPurchaseorderrequestCreate: false,
                        sidebarPurchaseorderrequestShow: true,
                      })
                    }}
                    className="p-button"/>
            }
          </div>
          <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"}>
            {formData && formData.id && formData.status === 'new' &&
            <Button type="button" label="Beszerzési megrendeléssé alakítás" loading={loading} icon={"pi pi-clone"}
                    onClick={() => {
                      PurchaseorderRequestToOrderService(formData.id)
                        .then(response => {
                          window.App.toastShow('success', 'Sikeres művelet', 'A megrendelés igény megrendelésként lett elmentve.');
                          window.App.setState({
                            sidebarPurchaseorderrequestCreate: false,
                            sidebarPurchaseorderrequestUpdate: false,
                            rerenderPurchaseorderrequestList: true,
                            sidebarPurchaseorderShow: true,
                            props: response.data
                          })
                        })
                        .catch(error => {
                          window.App.toastShow('error', 'Hiba történt!', error.toString(), '', error.response);
                        })
                    }}
                    className="p-button p-ml-2"/>}
            <Button type="submit" label="Mentés és bezár" icon={"pi pi-check"}
                    loading={loading}
                    onClick={() => {
                      props = {...props, closeForm: true}
                    }}
                    className="p-button-success  p-ml-2"/>
            <Button type="submit" label="Mentés" icon={"pi pi-check"}
                    loading={loading}
                    onClick={() => {
                      props = {...props, closeForm: false}
                    }}
                    className="p-button-success p-ml-2"/>
          </div>
        </div>
      </form>
    )}/>
  )
}
export default withTranslation('common')(PurchaseorderrequestFormComponent);
