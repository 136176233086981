import axios from "axios";

export default function SubscriptionModulesService() {

  return axios({
    method: 'get',
    url: process.env.REACT_APP_SUBSCRIPTION_API_HOST + "/subscription_modules",
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken"), 'App-Network': localStorage.getItem("networkId")}
  });
}
