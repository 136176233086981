import axios from "axios";

export default function SubscriptionListService() {

  return axios({
    method: 'get',
    url: process.env.REACT_APP_SUBSCRIPTION_API_HOST + "/subscription_all",
    params: {},
    headers: {'Authorization': 'Bearer ' + localStorage.getItem("userToken"), 'App-Network': localStorage.getItem("networkId")}
  });
}
